"use client";

import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import Link from "next/link";
import Image from "next/image";
import Avatar from "boring-avatars";
import { twMerge } from "tailwind-merge";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { generateSeed } from "@/app/helpers/generateSeed";
import { useDisconnect, useAccount, useEnsName, useEnsAvatar } from "wagmi";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import {
  truncateAddress,
  truncateAddressMobile,
} from "@/app/helpers/truncateAddress";
import { useAtom } from "jotai";
import { usePathname } from "next/navigation";
import {
  usersDataAtom,
  combinedNftsAtom,
  userHasAssetsAtom,
} from "@/app/atoms/usersData";
import ProfileDropdown from "./ProfileDropdown";
import { KARRAT_TOKEN_SYMBOL } from "@/app/constants/karratToken";

const Profile = () => {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { data: ensName } = useEnsName({ address });
  const { data: ensAvatar } = useEnsAvatar({ name: ensName || "" });
  const [copySuccess, setCopySuccess] = useState(false);
  const [userData] = useAtom(usersDataAtom);
  const [combinedNfts] = useAtom(combinedNftsAtom);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [buttonWidth, setButtonWidth] = useState<number | undefined>(undefined);
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false); // Track click state
  const [userHasAssets] = useAtom(userHasAssetsAtom);
  const pathname = usePathname();
  const dropdownRef = useRef<HTMLDivElement>(null); // Ref for dropdown container

  const isHome = pathname === "/";

  // Close the dropdown when clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsHovered(false);
        setIsClicked(false); // Close on outside click
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useLayoutEffect(() => {
    const updateButtonWidth = () => {
      if (buttonRef.current) {
        const width = buttonRef.current.offsetWidth;
        setButtonWidth(width);
      }
    };

    updateButtonWidth();

    const observer = new MutationObserver(updateButtonWidth);
    if (buttonRef.current) {
      observer.observe(buttonRef.current, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [address, userData]);

  if (!userData) {
    return null;
  }

  const { karratBalance } = userData;
  const userHasKarrats = Number(karratBalance?.raw) > 0;

  const handleCopy = async () => {
    if (address) {
      try {
        await navigator.clipboard.writeText(address);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 3000);
      } catch (err) {
        setCopySuccess(false);
      }
    }
  };

  const usersAddress =
    address &&
    (copySuccess ? (
      <div className="flex whitespace-nowrap justify-end mx-auto mid:ml-auto">
        Copied!
      </div>
    ) : (
      <div className="flex whitespace-nowrap items-center" onClick={handleCopy}>
        <span className="flex max-mid:hidden">{truncateAddress(address)}</span>
        <span className="hidden max-mid:flex">
          {truncateAddressMobile(address)}
        </span>
        <ClipboardDocumentIcon className="inline-block ml-1 w-4 h-4 mb-1" />
      </div>
    ));

  const handleDisconnect = () => {
    disconnect();
    setIsHovered(false);
    setIsClicked(false);
  };

  const dropdownContent = [
    ensName && (
      <div key="address" className="px-4 py-2 justify-center flex">
        {usersAddress}
      </div>
    ),
    userHasKarrats && (
      <div
        key="karratBalance"
        className="cursor-default px-4 py-2 flex justify-center whitespace-nowrap"
      >
        {karratBalance?.formatted} {KARRAT_TOKEN_SYMBOL}
        <span className="lowercase">s</span>
      </div>
    ),
    !!userHasAssets && (
      <Link
        key="profile"
        href="/dashboard"
        className="cursor-pointer px-4 py-2 flex justify-center whitespace-nowrap"
      >
        Dashboard
      </Link>
    ),
    <button
      key="connect"
      onClick={() => open()}
      className="flex cursor-pointer px-4 py-2 uppercase mx-auto"
    >
      Open Wallet
    </button>,
    <button
      key="disconnect"
      onClick={handleDisconnect}
      className="flex cursor-pointer px-4 py-2 uppercase mx-auto"
    >
      Disconnect
    </button>,
  ].filter(Boolean);

  if (!isConnected) return null;

  const showDropdown = isHovered || isClicked;

  return (
    <div
      className={twMerge(
        "relative flex items-center max-w-max px-4 py-1 uppercase z-40 ml-auto",
        isHome && "xxl:mr-40",
      )}
      ref={dropdownRef} // Ref for dropdown container
    >
      {address && (
        <div
          className={twMerge(
            "relative group min-w-[165px]",
            showDropdown && "bg-dark",
          )}
          ref={buttonRef}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => setIsClicked(!isClicked)} // Toggle on click
          style={{
            borderRadius: "0.375rem 50px 0 0",
          }}
        >
          <button
            className={twMerge(
              `flex items-center rounded-tl-md max-h-[50px] max-w-[100px] py-4 text-neutral-50 uppercase whitespace-nowrap min-w-[165px] ${
                ensAvatar ? "mr-12" : "mr-8"
              }`,
            )}
          >
            <div className="pl-4 min-w-[112px]">
              {!!ensName ? ensName : usersAddress}
            </div>
            <ChevronDownIcon
              className="w-4 h-4 mid:ml-2 transition-transform duration-300 transform group-hover:-rotate-180 mr-8"
              fill="currentColor"
            />
          </button>
          <ProfileDropdown
            isVisible={showDropdown}
            content={dropdownContent}
            buttonWidth={buttonWidth}
          />
        </div>
      )}
      {ensAvatar ? (
        <Image
          src={ensAvatar}
          alt="Avatar"
          width={60}
          height={60}
          className="absolute right-5 rounded-full pointer-events-auto z-50 cursor-pointer"
          onClick={() => setIsClicked(!isClicked)}
        />
      ) : (
        address && (
          <div
            className="absolute right-5 rounded-full z-50 pointer-events-auto cursor-pointer"
            onClick={() => setIsClicked(!isClicked)}
          >
            <Avatar
              size={42}
              name={generateSeed(address)}
              variant="sunset"
              colors={[
                "#0782b7",
                "#ed2e94",
                "#fa9005",
                "#083ff4d4",
                "#3ec300",
                "#f4f807",
                "#b01eca",
              ]}
            />
          </div>
        )
      )}
    </div>
  );
};

export default Profile;
