"use client";

import { useEffect } from "react";
import { useAtom } from "jotai";
import debounce from "debounce";
import { heroNftsAtom } from "@/app/atoms/heroNFTs";

export const useGetHeroNFTs = () => {
  const [heroNFT, setHeroNFT] = useAtom(heroNftsAtom);

  const fetchHeroNFTData = debounce(async () => {
    try {
      const response = await fetch("/api/get-random-heroes");
      const data = await response.json();
      setHeroNFT(data);
    } catch (error) {
      console.log("Error fetching hero NFT data:", error);
    }
  }, 200);

  useEffect(() => {
    fetchHeroNFTData();

    return () => {
      fetchHeroNFTData.clear();
    };
    /* eslint-disable */
  }, []);

  return heroNFT;
};
