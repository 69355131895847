"use client";

import { useCallback, useEffect } from "react";
import { useAtom } from "jotai";
import { usersZuckbotsAtom } from "@/app/atoms/usersData";
import { useAccount } from "wagmi";

export const useGetUserZuckbots = (address: string) => {
  const [userZuckbots, setUserZuckbots] = useAtom(usersZuckbotsAtom);

  const refetch = useCallback(
    async (address: string) => {
      try {
        const url = `${process.env.NEXT_PUBLIC_ZUCKBOTS_ENDPOINT}?owner=${address}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const text = await response.text();
        const data = text ? JSON.parse(text) : {};

        if (data && data.data) {
          setUserZuckbots(data.data);
        } else {
          setUserZuckbots([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setUserZuckbots([]);
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [address],
  );

  useEffect(
    () => {
      if (address) {
        refetch(address);
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [address],
  );

  return { userZuckbots, refetch };
};
