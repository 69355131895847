"use client";

import { readContract, getBalance } from "@wagmi/core";
import { formatUnits } from "ethers";
import { karratTokenABI } from "@/app/abi";
import { config } from "@/app/connection/config";
import { UsersData } from "@/app/atoms/usersData";
import { KARRAT_TOKEN_DECIMALS } from "@/app/constants/karratToken";

const formatNumber = (number: number, decimals: number = 4) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
};

export const fetchUserData = async (
  address: `0x${string}`,
): Promise<UsersData> => {
  try {
    const karratContract = process.env.NEXT_PUBLIC_TOKEN_ADDRESS;

    const [balance] = await Promise.all([
      readContract(config, {
        address: karratContract as `0x${string}`,
        abi: karratTokenABI,
        functionName: "balanceOf",
        args: [address],
      }) as Promise<bigint>,
    ]);

    const formattedKarratBalance = formatNumber(
      parseFloat(formatUnits(balance, KARRAT_TOKEN_DECIMALS)),
    );

    const nativeBalance = await getBalance(config, { address });

    const formattedNativeBalance = nativeBalance.value
      ? formatNumber(parseFloat(formatUnits(nativeBalance.value, 18)))
      : "0.0000";

    return {
      nativeBalance: formattedNativeBalance,
      nativeSymbol: "ETH",
      karratBalance: {
        raw: balance,
        formatted: `${formattedKarratBalance}`,
      },
    };
  } catch (error) {
    console.error("Error fetching user data:", error);
    return {
      nativeBalance: "0.0000",
      nativeSymbol: "ETH",
      karratBalance: {
        raw: BigInt(0),
        formatted: "0.0000",
      },
    };
  }
};
