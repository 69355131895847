import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/16/solid";
import MobileIcon from "./MobileIcon";
import { navItems } from "./navItems";
import { useSetAtom, useAtom } from "jotai";
import { originAtom } from "@/app/atoms/routing";
import { mobileNavStateAtom } from "@/app/atoms/nav";
import { Tab } from "@/app/types/dashboard";
import { Bars3Icon } from "@heroicons/react/24/outline";

const MobileNav = () => {
  const [isOpen, setIsOpen] = useAtom(mobileNavStateAtom);
  const [openItemIndex, setOpenItemIndex] = useState<number | null>(null);
  const [buttonWidths, setButtonWidths] = useState<number[]>([]);
  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const setOrigin = useSetAtom(originAtom);

  useEffect(() => {
    setButtonWidths(
      buttonRefs.current.map((button) => button?.offsetWidth || 0),
    );
  }, []);

  const handleItemClick = (index: number) => () => {
    setOpenItemIndex(openItemIndex === index ? null : index);
  };

  const handleMarketClick = (tab: Tab) => {
    setOrigin({ path: "/market", tab });
    setIsOpen(false);
  };

  const handleMarketplaceClick = () => {
    setOrigin({ path: "/market", tab: "All" });
    setIsOpen(false);
  };

  const handleCloseClick = () => {
    setIsOpen(false);
  };

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="nav:ml-auto z-50">
      <div
        className={`w-8 cursor-pointer relative z-50 font-semibold ${isOpen && "max-min:fixed max-min:hidden"}`}
        onClick={toggleOpen}
      >
        <Bars3Icon width={20} className={`mb-2 z-0`} />
      </div>
      {isOpen && (
        <div className="fixed lg:hidden uppercase text-sm backdrop-blur-sm min-h-screen min-w-full left-0 top-0 right-0 bottom-0 pt-[150px] z-40">
          {navItems.map((item, index) => {
            const isLastItem = index === navItems.length - 1;
            const itemHeight = item.options.length * 48;

            return (
              <div
                key={index}
                className={`relative flex flex-col items-center overflow-hidden transition-all duration-300 ease-in-out border-t border-neutral-800 bg-neutral-950 ${
                  !isLastItem ||
                  (isLastItem && openItemIndex !== index && "border-b")
                }`}
                style={
                  openItemIndex === index
                    ? { minHeight: `${itemHeight}px` }
                    : {}
                }
              >
                <button
                  ref={(el) => {
                    buttonRefs.current[index] = el;
                  }}
                  onClick={handleItemClick(index)}
                  className={`flex items-center justify-between w-full px-4 py-2 text-neutral-50 uppercase whitespace-nowrap bg-dark hover:bg-hover `}
                >
                  {item.title}
                  {openItemIndex === index ? (
                    <ChevronDownIcon
                      className="transition-transform duration-300 w-4 h-4"
                      fill="currentColor"
                    />
                  ) : (
                    <ChevronRightIcon
                      className="transition-transform duration-300 w-4 h-4"
                      fill="currentColor"
                    />
                  )}
                </button>

                <div
                  className={`transition-all duration-300 ease-in-out min-w-full ${
                    openItemIndex === index ? "max-h-screen" : "max-h-0"
                  } overflow-hidden`}
                >
                  {openItemIndex === index && (
                    <div className="p-0 m-0 list-none min-w-full flex flex-col">
                      {item.options.map((option, optIndex) => (
                        <Link
                          key={option.path}
                          href={option.path}
                          onClick={() =>
                            option.tab && handleMarketClick(option.tab as Tab)
                          }
                          target={option.target}
                          rel={option.rel}
                          className={`cursor-pointer py-2 px-4 bg-dark w-full bg-opacity-60 hover:bg-[#303B64]`}
                        >
                          <div className="w-full">{option.title}</div>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <Link
            href="/market"
            className="flex items-center justify-between w-full px-4 py-2 text-neutral-50 uppercase whitespace-nowrap hover:bg-hover bg-dark"
            onClick={handleMarketplaceClick}
          >
            Marketplace
          </Link>
          <div
            className="flex items-center justify-between w-full px-4 py-2 text-neutral-50 uppercase whitespace-nowrap hover:bg-hover bg-dark cursor-pointer border-t border-neutral-800"
            onClick={handleCloseClick}
          >
            Close
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileNav;
