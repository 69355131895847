"use client";

import React, { useEffect } from "react";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import { useAtom } from "jotai";
import { usersDataAtom } from "@/app/atoms/usersData";
import { fetchUserData } from "@/app/actions/fetchUserData";
import { karratTokenABI } from "@/app/abi";

const TokenBalanceListener: React.FC = () => {
  const { address, isConnected } = useAccount();
  const [, setUserData] = useAtom(usersDataAtom);

  useEffect(() => {
    const provider = process.env.NEXT_PUBLIC_WSS_PROVIDER
      ? new ethers.WebSocketProvider(process.env.NEXT_PUBLIC_WSS_PROVIDER)
      : new ethers.JsonRpcProvider(process.env.NEXT_PUBLIC_PROVIDER_URL);

    let karratContract: ethers.Contract;

    const updateUserBalance = async () => {
      if (address) {
        const data = await fetchUserData(address as `0x${string}`);
        setUserData(data);
      }
    };

    if (isConnected && address) {
      karratContract = new ethers.Contract(
        process.env.NEXT_PUBLIC_TOKEN_ADDRESS as `0x${string}`,
        karratTokenABI,
        provider,
      );

      karratContract.on("Transfer", (from, to) => {
        if (from === address || to === address) {
          updateUserBalance();
        }
      });
    }

    return () => {
      if (provider && karratContract) {
        karratContract.removeAllListeners("Transfer");
      }
    };
  }, [address, isConnected, setUserData]);

  return null;
};

export default TokenBalanceListener;
