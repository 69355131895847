import { atom } from "jotai";
import { Database } from "@/app/types/database";
import { NFT } from "../types/nfts";

export const listedNftsDBAtom = atom<Database[]>([]);

export const postMadeAtom = atom<boolean>(false);

export const hooligansAtom = atom<NFT[]>([]);

export const theZuckbotsAtom = atom<NFT[]>([]);
