export const GameAssetsJson = [
  {
    tokenId: "0",
    gameId: "ID_Bunny_Shirt_BasicTee_BTCGlitch_Black",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404715/ID_Bunny_Shirt_BasicTee_BTCGlitch_Black_mitflo.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405136/ID_Bunny_Shirt_BasicTee_BTCGlitch_Black_nq9iey.png",
    name: "BTC Glitch Black Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "1",
    gameId: "ID_Bunny_Shirt_BasicTee_BTCGrid_Black",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404693/ID_Bunny_Shirt_BasicTee_BTCGrid_Black_xa415e.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405094/ID_Bunny_Shirt_BasicTee_BTCGrid_Black_dvwjpf.png",
    name: "BTC Grid Black Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "2",
    gameId: "ID_Bunny_Shirt_BasicTee_BTCTriangles_White",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404707/ID_Bunny_Shirt_BasicTee_BTCTriangles_White_vesetn.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405122/ID_Bunny_Shirt_BasicTee_BTCTriangles_White_ycakas.png",
    name: "BTC Triangles White Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "3",
    gameId: "ID_Bunny_Shirt_BasicTee_ETHClouds_White",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404708/ID_Bunny_Shirt_BasicTee_ETHClouds_White_sfwosq.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405124/ID_Bunny_Shirt_BasicTee_ETHClouds_White_kx9ioo.png",
    name: "ETH Clouds White Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "4",
    gameId: "ID_Bunny_Shirt_BasicTee_ETHGrid_Green",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404701/ID_Bunny_Shirt_BasicTee_ETHGrid_Green_hnrt0s.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405109/ID_Bunny_Shirt_BasicTee_ETHGrid_Green_xz3ftq.png",
    name: "ETH Grid Green Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "5",
    gameId: "ID_Bunny_Shirt_BasicTee_ETHPlanet_Gray",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404697/ID_Bunny_Shirt_BasicTee_ETHPlanet_Gray_ikismg.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405101/ID_Bunny_Shirt_BasicTee_ETHPlanet_Gray_dreoc2.png",
    name: "ETH Planet Gray Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "6",
    gameId: "ID_Bunny_Shirt_BasicTee_ETHSync_Black",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404695/ID_Bunny_Shirt_BasicTee_ETHSync_Black_rfucx4.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405097/ID_Bunny_Shirt_BasicTee_ETHSync_Black_n9cecw.png",
    name: "ETH Sync Black Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "7",
    gameId: "ID_Bunny_Shirt_BasicTee_ETHUnderwater_White",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404711/ID_Bunny_Shirt_BasicTee_ETHUnderwater_White_z9vug7.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405129/ID_Bunny_Shirt_BasicTee_ETHUnderwater_White_ubycdk.png",
    name: "ETH Underwater White Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "8",
    gameId: "ID_Bunny_Shirt_BasicTee_ETHWarped_Black",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404992/ID_Bunny_Shirt_BasicTee_ETHWarped_Black_weukil.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405095/ID_Bunny_Shirt_BasicTee_ETHWarped_Black_s6yuhe.png",
    name: "ETH Warped Black Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "9",
    gameId: "ID_Bunny_Shirt_BasicTee_HODLGym_Yellow",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404946/ID_Bunny_Shirt_BasicTee_HODLGym_Yellow_utaeyv.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405108/ID_Bunny_Shirt_BasicTee_HODLGym_Yellow_ukuewh.png",
    name: "HODL Gym Yellow Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "10",
    gameId: "ID_Bunny_Shirt_BasicTee_HODLMPH_Yellow",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405031/ID_Bunny_Shirt_BasicTee_HODLMPH_Yellow_djecyt.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405092/ID_Bunny_Shirt_BasicTee_HODLMPH_Yellow_vgcu8y.png",
    name: "HODL MPH Yellow Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "11",
    gameId: "ID_Bunny_Shirt_BasicTee_HODLRepeat_Red",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404906/ID_Bunny_Shirt_BasicTee_HODLRepeat_Red_zlpbjg.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405127/ID_Bunny_Shirt_BasicTee_HODLRepeat_Red_rjon1q.png",
    name: "HODL Repeat Red Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "12",
    gameId: "ID_Bunny_Shirt_BasicTee_KarratBox_Black",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404912/ID_Bunny_Shirt_BasicTee_KarratBox_Black_c85nvf.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405131/ID_Bunny_Shirt_BasicTee_KarratBox_Black_mnmhto.png",
    name: "Karrat Box Black Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "13",
    gameId: "ID_Bunny_Shirt_BasicTee_KarratBoxWing_Yellow",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404952/ID_Bunny_Shirt_BasicTee_KarratBoxWing_Yellow_s7lngk.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405106/ID_Bunny_Shirt_BasicTee_KarratBoxWing_Yellow_mqnmdz.png",
    name: "Karrat Wing Yellow Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "14",
    gameId: "ID_Bunny_Shirt_KarratTwoTone_White",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404706/ID_Bunny_Shirt_KarratTwoTone_White_crbckd.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405120/ID_Bunny_Shirt_KarratTwoTone_White_axn9ge.png",
    name: "Two-Tone Karrat Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "15",
    gameId: "ID_Bunny_Shirt_KarratCyber_Black",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404699/ID_Bunny_Shirt_KarratCyber_Black_afr7op.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405104/ID_Bunny_Shirt_KarratCyber_Black_u1mrkm.png",
    name: "Cyber Karrat Black Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "16",
    gameId: "ID_Bunny_Shirt_KarratDoubleBox_Black",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404702/ID_Bunny_Shirt_KarratDoubleBox_Black_rk4orp.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405111/ID_Bunny_Shirt_KarratDoubleBox_Black_zqqv9p.png",
    name: "Karrat+ Black Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "17",
    gameId: "ID_Bunny_Shirt_KarratFutbolChecker_Yellow",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404714/ID_Bunny_Shirt_KarratFutbolChecker_Yellow_yl45cp.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405134/ID_Bunny_Shirt_KarratFutbolChecker_Yellow_vyxauq.png",
    name: "Karrat Checkered Yellow Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "19",
    gameId: "ID_Bunny_Shirt_BasicTee_KarratLogo_Black",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404987/ID_Bunny_Shirt_BasicTee_KarratLogo_Black_bxwmx9.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405099/ID_Bunny_Shirt_BasicTee_KarratLogo_Black_bc2waj.png",
    name: "Karrat Logo Black Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "20",
    gameId: "ID_Bunny_Shirt_KarratMulti_Black",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404704/ID_Bunny_Shirt_KarratMulti_Black_nefex2.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405116/ID_Bunny_Shirt_KarratMulti_Black_peoisg.png",
    name: "Karrat Repeat Black Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "21",
    gameId: "ID_Bunny_Shirt_BasicTee_KarratSmall_Black",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404703/ID_Bunny_Shirt_BasicTee_KarratSmall_Black_kexvzv.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405113/ID_Bunny_Shirt_BasicTee_KarratSmall_Black_qswtuz.png",
    name: "Small Karrat Black Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "23",
    gameId: "ID_Bunny_Shirt_KarratStripes_Black",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404698/ID_Bunny_Shirt_KarratStripes_Black_pv0etu.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405103/ID_Bunny_Shirt_KarratStripes_Black_opum5m.png",
    name: "Karrat Stripes Black Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "24",
    gameId: "ID_Bunny_Shirt_KarratUpsideDown_Black",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404692/ID_Bunny_Shirt_KarratUpsideDown_Black_vafeef.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405090/ID_Bunny_Shirt_KarratUpsideDown_Black_zwzjwa.png",
    name: "Upside Down Karrat Black Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "25",
    gameId: "ID_Bunny_Shirt_NeverUnderestimate_Black",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404709/ID_Bunny_Shirt_NeverUnderestimate_Black_scb0vw.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405125/ID_Bunny_Shirt_NeverUnderestimate_Black_oeb6ev.png",
    name: "Never Underestimate Black Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "26",
    gameId: "ID_Bunny_Shirt_RIPNFT_White",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404713/ID_Bunny_Shirt_RIPNFT_White_uwrddn.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405133/ID_Bunny_Shirt_RIPNFT_White_vaonnx.png",
    name: "RIP NFT White Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "27",
    gameId: "ID_Bunny_Shirt_UsedtobeRich_Blue",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404704/ID_Bunny_Shirt_UsedtobeRich_Blue_xbhxzk.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405115/ID_Bunny_Shirt_UsedtobeRich_Blue_wy0kpq.png",
    name: "Used to Be Rich Blue Tee",
    traits: {},
    type: "GameAsset",
  },
  {
    tokenId: "28",
    gameId: "ID_Bunny_Shirt_WENSOON_Black",
    tokenURI: "",
    image:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725404705/ID_Bunny_Shirt_WENSOON_Black_qdjc4h.png",
    display:
      "https://res.cloudinary.com/diorwbih0/image/upload/v1725405118/ID_Bunny_Shirt_WENSOON_Black_kl614b.png",
    name: "Wen Soon",
    traits: {},
    type: "GameAsset",
  },
];
