"use client";

import { useEffect, useCallback, useState, useRef } from "react";
import { useAtom } from "jotai";
import { cartItemsAtom } from "@/app/atoms/cart";

export const useGetCart = () => {
  const [cartItems, setCartItems] = useAtom(cartItemsAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);

  const initialLoadRef = useRef(false);

  const refetch = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(process.env.NEXT_PUBLIC_CART_ENDPOINT!, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data) {
        setCartItems(data);
      } else {
        setCartItems([]);
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setError(error);
      setCartItems([]);
    } finally {
      setIsLoading(false);
    }
  }, [setCartItems]);

  useEffect(() => {
    if (!initialLoadRef.current) {
      refetch();
      initialLoadRef.current = true;
    }
  }, [refetch]);

  return { cartItems, isLoading, error, refetch };
};
