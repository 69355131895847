"use client";

import { useEffect, useCallback } from "react";
import { useAtom } from "jotai";
import { listedNftsDBAtom } from "@/app/atoms/database";

export const useGetListings = () => {
  const [listedNftsDB, setListedNftsDB] = useAtom(listedNftsDBAtom);

  const refetch = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.NEXT_PUBLIC_LISTEDNFTS_ENDPOINT!,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data) {
        setListedNftsDB(data);
      } else {
        setListedNftsDB([]);
      }
    } catch (error) {
      console.error("Error fetching listings:", error);
      setListedNftsDB([]);
    }
  }, [setListedNftsDB]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return { listedNftsDB, refetch };
};
