import React from "react";
import Link from "next/link";
import { NavDropdownProps } from "@/app/types/nav";
import { useSetAtom } from "jotai";
import { originAtom } from "@/app/atoms/routing";
import { Tab } from "@/app/types/dashboard"; // Ensure this is the correct import

const NavDropdown: React.FC<NavDropdownProps> = ({
  isVisible,
  content,
  buttonWidth,
  className,
}) => {
  const setOrigin = useSetAtom(originAtom);

  const handleMarketClick = (tab: Tab) => {
    setOrigin({ path: "/market", tab });
  };

  if (!isVisible) return null;

  return (
    <div
      className="absolute left-0 rounded-b-md z-50 text-neutral-50 p-0 m-0 bg-neutral-950"
      style={{
        minWidth: `${buttonWidth}px`,
        maxWidth: `${buttonWidth}px`,
      }}
    >
      <ul className="p-0 m-0 list-none w-full">
        {content.map((option, index) => {
          const listItem = (
            <li
              key={index}
              className={`hover:bg-[#303B64] cursor-pointer py-2 px-4 whitespace-nowrap bg-dark ${
                index === content.length - 1 ? "rounded-b-md" : ""
              } ${className ?? ""}`}
            >
              {option.title}
            </li>
          );

          // Check if the option has a `tab`, meaning it should change the marketplace tab
          if (option.tab) {
            return (
              <Link
                href={option.path}
                key={index}
                onClick={() => handleMarketClick(option.tab as Tab)}
              >
                {listItem}
              </Link>
            );
          }

          // If the option doesn't have a tab, it's either an external link or a regular link
          return option.path ? (
            <Link
              href={option.path}
              target={option.target}
              rel={option.rel}
              key={index}
            >
              {listItem}
            </Link>
          ) : (
            listItem
          );
        })}
      </ul>
    </div>
  );
};

export default NavDropdown;
