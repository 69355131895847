"use client";

import { useEffect, useState, useRef } from "react";
import { useSetAtom } from "jotai";
import { newEventDetectedAtom } from "@/app/atoms/websockets";
import { useGetListings } from "@/app/hooks/Database/useGetListings";
import { useGetCart } from "@/app/hooks/Database/useGetCart";
import { useGetUserHooligans } from "@/app/hooks/Database/useGetUserHooligans";
import { useGetUserZuckbots } from "@/app/hooks/Database/useGetUserZuckbots";
import { fetchUserData } from "@/app/actions/fetchUserData";
import { useAccount } from "wagmi";

type WebSocketMessage = {
  type: "BLOCKCHAIN_EVENT" | "API_NOTIFICATION";
  content: string;
  data: string;
};

const useWebSocket = (): WebSocketMessage[] => {
  const [messages, setMessages] = useState<WebSocketMessage[]>([]);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const { refetch: refreshListings } = useGetListings();
  const { refetch: refreshCart } = useGetCart();
  const { address } = useAccount();
  const addressRef = useRef(address);
  const { refetch: refreshUserHooligans } = useGetUserHooligans(
    address as string,
  );
  const { refetch: refreshUserZuckbots } = useGetUserZuckbots(
    address as string,
  );
  useEffect(() => {
    addressRef.current = address;
  }, [address]);

  useEffect(() => {
    const connectWebSocket = () => {
      const ws = new WebSocket(process.env.NEXT_PUBLIC_WSS_CONNECTION!);

      // ws.onopen = () => {
      // 	console.log("WebSocket connection opened");
      // };

      ws.onmessage = (event: MessageEvent) => {
        try {
          const message: WebSocketMessage = JSON.parse(event.data);
          setMessages((prevMessages) => [...prevMessages, message]);

          if (message.type === "API_NOTIFICATION") {
            refreshListings();
            refreshCart();
          } else if (message.type === "BLOCKCHAIN_EVENT") {
            const currentAddress = addressRef.current;
            if (currentAddress) {
              refreshUserHooligans(currentAddress);
              refreshUserZuckbots(currentAddress);
            }

            refreshCart();
            refreshListings();
          }
        } catch (error) {
          console.error("Error parsing message:", error);
        }
      };

      ws.onclose = () => {
        // console.log("WebSocket Disconnected. Attempting to reconnect...");
        setTimeout(() => {
          connectWebSocket();
        }, 5000);
      };

      ws.onerror = (error: Event) => {
        console.error("WebSocket Error:", error);
        ws.close();
      };

      setSocket(ws);
    };

    connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return messages;
};

export default useWebSocket;
