import React from "react";
import { ProfileDropdownProps } from "@/app/types/nav";

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
  isVisible,
  content,
  buttonWidth,
  className,
}) => {
  if (!isVisible) return null;

  const adjustedWidth = buttonWidth ? `${buttonWidth}px` : "auto";

  return (
    <div
      className="absolute left-0 rounded-b-md z-50 text-neutral-50 p-0 m-0 bg-neutral-950"
      style={{
        width: adjustedWidth,
      }}
    >
      <ul className="p-0 m-0 list-none w-full">
        {content.map((option, index) => (
          <li
            key={index}
            className={`hover:bg-[#303B64] cursor-pointer py-2 px-4 bg-dark ${
              index === content.length - 1 ? "rounded-b-md" : ""
            } ${className ?? ""}`}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProfileDropdown;
