"use client";

import React, {
  Dispatch,
  SetStateAction,
  useState,
  useCallback,
  useEffect,
} from "react";

const LockScreen = ({
  setPassword,
  password,
  setIsLocked,
}: {
  setPassword: any;
  password: string;
  setIsLocked: Dispatch<SetStateAction<boolean>>;
}) => {
  const [attempts, setAttempts] = useState(0);
  const [error, setError] = useState("");

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = useCallback(() => {
    const errorMessages = [
      "Nope. Try again.",
      "Still not right.",
      "Incorrect.",
      "Give it another shot.",
    ];

    if (password === process.env.NEXT_PUBLIC_PASSWORD) {
      setAttempts(0);
      setError("");
      setIsLocked(false);
    } else {
      const newAttempts = attempts + 1;
      setAttempts(newAttempts);
      if (newAttempts > 3) {
        setError("ACCESS DENIED");
      } else {
        setError(errorMessages[newAttempts - 1]);
      }
    }
    setPassword("");
  }, [password, attempts, setPassword, setIsLocked]);

  const handleKeyPress = useCallback(
    (e: any) => {
      if (e.key === "Enter") {
        handlePasswordSubmit();
      }
    },
    [handlePasswordSubmit],
  );
  useEffect(() => {
    const inputElement = document.querySelector('input[type="password"]');
    if (inputElement) {
      inputElement.addEventListener("keypress", handleKeyPress);
    }

    // Cleaning up event listener on component unmount
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("keypress", handleKeyPress);
      }
    };
  }, [handleKeyPress]);

  return (
    <form
      id="lockscreen"
      className={`flex min-h-screen min-w-screen bg-black text-neutral-200 flex-col justify-center items-center`}
    >
      {attempts > 3 ? (
        <div className="text-orange-600 font-bold text-xl">ACCESS DENIED</div>
      ) : (
        <div className="min-h-screen flex flex-col justify-center items-center">
          <input
            type="password"
            name="password"
            placeholder="Enter password"
            className="bg-neutral-50 text-neutral-600 p-2 rounded-md text-center placeholder-gray-500 mb-2 outline-none"
            value={password}
            onChange={handlePasswordChange}
          />
          <div
            className={`${error ? "opacity-1" : "opacity-0"} flex items-center min-h-[30px] text-orange-600 font-bold`}
          >
            {error ?? error}
          </div>
          <button
            onClick={handlePasswordSubmit}
            className="mt-2 bg-blue-600 text-white p-2 rounded-md"
          >
            Submit
          </button>
        </div>
      )}
    </form>
  );
};

export default LockScreen;
