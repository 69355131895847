"use client";

import React, { useState, useRef, useEffect, useCallback } from "react";
import Link from "next/link";
import { useSetAtom } from "jotai";
import { originAtom } from "@/app/atoms/routing";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { navItems } from "./navItems";
import NavDropdown from "./NavDropdown";

const DesktopNav = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [buttonWidths, setButtonWidths] = useState<number[]>([]);
  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const setOrigin = useSetAtom(originAtom);

  const calculateWidths = useCallback(() => {
    const widths = buttonRefs.current.map((button) => {
      if (button) {
        const rect = button.getBoundingClientRect();
        return rect.width;
      }
      return 0;
    });
    setButtonWidths(widths);
  }, []);

  useEffect(() => {
    calculateWidths();
  }, [calculateWidths]);

  useEffect(() => {
    const handleResize = () => {
      calculateWidths();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [calculateWidths]);

  const handleMouseEnter = (index: number) => () => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleMarketplaceClick = () => {
    setOrigin({ path: "/market", tab: "All" });
  };

  return (
    <div className="flex-1 hidden lg:flex py-1 gap-2 uppercase text-sm mx-auto z-40">
      {navItems.map((item, index) => (
        <div
          key={index}
          onMouseLeave={handleMouseLeave}
          className="relative"
          onMouseEnter={handleMouseEnter(index)}
        >
          <button
            ref={(el) => {
              buttonRefs.current[index] = el;
            }}
            className={`${
              hoveredIndex === index && "bg-dark"
            } flex items-center max-h-[50px] min-w-[100px] px-4 py-2 text-neutral-50 uppercase whitespace-nowrap rounded-t-md`}
          >
            {item.title}
            <ChevronDownIcon
              className={`transition-transform duration-300 ${
                hoveredIndex === index ? "transform -rotate-180" : ""
              } w-4 h-4 ml-2`}
              fill="currentColor"
            />
          </button>
          {hoveredIndex === index && (
            <NavDropdown
              isVisible={true}
              content={item.options}
              buttonWidth={buttonWidths[index]}
              className="px-4 py-2"
            />
          )}
        </div>
      ))}
      <Link
        href="/market"
        className="py-2 bg-transparent hover:bg-dark px-4 rounded-md"
        onClick={handleMarketplaceClick}
      >
        Marketplace
      </Link>
    </div>
  );
};

export default DesktopNav;
