"use client";

import React, { useEffect, useState } from "react";
import { useSetAtom, useAtom } from "jotai";
import { useAccount } from "wagmi";
import {
  usersDataAtom,
  usersHooligansAtom,
  usersZuckbotsAtom,
} from "@/app/atoms/usersData";
import { useRouter } from "next/navigation";
import {
  cartOpenAtom,
  cartItemsAtom,
  buyModalOpenAtom,
} from "@/app/atoms/cart";
import TokenBalanceListener from "@/app/helpers/TokenBalanceListener";
import { fetchUserData } from "@/app/actions/fetchUserData";
import LockScreen from "@/app/components/LockScreen";
import { useGetListings } from "@/app/hooks/Database/useGetListings";
import { gameAssetsAtom } from "@/app/atoms/gameAssets";
import useWebSocket from "@/app/hooks/Database/useWebSocket";
import { useGetHeroNFTs } from "../hooks/Database/useGetHeroNFTs";
import { useGetUserHooligans } from "@/app/hooks/Database/useGetUserHooligans";
import { useGetUserZuckbots } from "@/app/hooks/Database/useGetUserZuckbots";
import { GameAssetsJson } from "../lib/GameAssets";
import getOwnerOfNFT from "../helpers/getNftOwner";

const ClientLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [, setUsersHooligans] = useAtom(usersHooligansAtom);
  const setUsersZuckbots = useSetAtom(usersZuckbotsAtom);
  const setUsersData = useSetAtom(usersDataAtom);
  const setGameAssets = useSetAtom(gameAssetsAtom);
  const { address, isConnected } = useAccount();
  const [password, setPassword] = useState("");
  const lockApp = process.env.NEXT_PUBLIC_LOCK_APP === "true";
  const [isLocked, setIsLocked] = useState(lockApp);
  const [, setCartOpen] = useAtom(cartOpenAtom);
  const [, setCartItems] = useAtom(cartItemsAtom);
  const [, setBuyModalOpen] = useAtom(buyModalOpenAtom);
  const router = useRouter();

  useGetHeroNFTs();
  useGetListings();
  useGetUserHooligans(address as string);
  useGetUserZuckbots(address as string);
  useWebSocket();

  useEffect(() => {
    const fetchAndSetUserData = async () => {
      if (address) {
        const userData = await fetchUserData(address as `0x${string}`);

        setUsersData(userData);
      }
    };

    if (address) {
      fetchAndSetUserData();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [address]);

  // Keep for troubleshooting
  // const ownerOfNft = getOwnerOfNFT(
  // 	process.env.NEXT_PUBLIC_MPH_ADDRESS!,
  // 	246,
  // 	mphABI
  // );

  // console.log('owner of hooligan 246: ', ownerOfNft);

  useEffect(() => {
    if (!isConnected || !address) {
      setCartItems([]);
      setCartOpen(false);
      setBuyModalOpen(false);
      router.push("/"); // Redirect to home page
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isConnected, address]);

  useEffect(() => {
    const fetchGameAssetsData = async () => {
      const gameAssets = GameAssetsJson.map((gameAsset) => {
        return {
          ...gameAsset,
          image: gameAsset.image,
        };
      }) as any;
      setGameAssets(gameAssets);
    };

    fetchGameAssetsData();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(
    () => {
      if (!isConnected || !address) {
        setUsersData({
          nativeBalance: "0.0000",
          nativeSymbol: "ETH",
          karratBalance: {
            formatted: "0.0000",
            raw: BigInt(0),
          },
        });
        setUsersHooligans([]);
        setUsersZuckbots([]);
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [isConnected, address],
  );

  return (
    <>
      {isLocked ? (
        <LockScreen
          password={password}
          setPassword={setPassword}
          setIsLocked={setIsLocked}
        />
      ) : (
        <>
          <TokenBalanceListener />
          {children}
        </>
      )}
    </>
  );
};

export default ClientLayout;
