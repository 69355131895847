import { atom } from "jotai";
import { NFT } from "@/app/types/nfts";

export interface UsersData {
  nativeBalance: string;
  nativeSymbol: string;
  karratBalance: {
    formatted: string;
    raw: bigint;
  };
}

export const usersDataAtom = atom<UsersData>({
  nativeBalance: "0.0000",
  nativeSymbol: "ETH",
  karratBalance: {
    formatted: "0.0000",
    raw: BigInt(0),
  },
});

export const usersHooligansAtom = atom<NFT[]>([]);
export const usersZuckbotsAtom = atom<NFT[]>([]);
export const usersTheOthersAtom = atom<NFT[]>([]);

export const combinedNftsAtom = atom((get) => {
  return [
    ...get(usersHooligansAtom),
    ...get(usersTheOthersAtom),
    ...get(usersZuckbotsAtom),
  ];
});

export const userHasAssetsAtom = atom((get) => {
  return get(combinedNftsAtom).length > 0;
});

export const userHasHooligansAtom = atom((get) => {
  return get(usersHooligansAtom).length > 0;
});

export const userHasZuckbotsAtom = atom((get) => {
  return get(usersZuckbotsAtom).length > 0;
});
