"use client";

import { useRef } from "react";
import Link from "next/link";
import Image from "next/image";
import logo from "@/public/mph_logo.png";
import Connect from "@/app/components/Connect";
import Profile from "@/app/components/Profile";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";

const Nav: React.FC = () => {
  const navRef = useRef<HTMLDivElement | null>(null);

  return (
    <nav ref={navRef} className="max-w-dvw">
      <div className="flex justify-between">
        <div className="flex items-center">
          <Link href={"/"} className="z-40 max-min:mr-auto mb-5">
            <Image
              src={logo}
              width={120}
              height={120}
              priority
              alt="mph logo"
              className="md:min-w-[120px] max-md:min-h-[40px] max-md:max-w-[40px]"
            />
          </Link>
          <div className="hidden lg:flex lg:ml-20">
            <DesktopNav />
          </div>
        </div>
        <div className="flex items-center max-min:mx-auto my-auto min:ml-auto relative">
          <Connect />
          <Profile />
          <div className="lg:hidden">
            <MobileNav />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
