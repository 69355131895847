"use client";

import { ReactNode } from "react";
import { usePathname } from "next/navigation";

type BackgroundLayoutProps = {
  children: ReactNode;
};

const BackgroundLayout = ({ children }: BackgroundLayoutProps) => {
  const pathname = usePathname();

  const backgroundImage =
    pathname === "/" ? `url('/flipped_bg.png')` : `url('/menu_bg.png')`;

  return (
    <div
      className="min-h-screen h-screen bg-cover bg-fixed overflow-hidden"
      style={{ backgroundImage }}
    >
      <div className="h-full overflow-hidden no-scrollbar">{children}</div>
    </div>
  );
};

export default BackgroundLayout;
