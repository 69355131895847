"use client";

import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { twMerge } from "tailwind-merge";
import { usePathname } from "next/navigation";

interface ConnectProps {
  className?: string;
  marketcard?: boolean;
}

export default function Connect({ className, marketcard }: ConnectProps) {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const pathname = usePathname();

  const isHome = pathname === "/";

  const handleConnection = () => {
    if (!isConnected) {
      open();
    }
  };

  if (isConnected) {
    return null;
  }

  return (
    <div
      className={twMerge(
        "flex flex-col w-full h-full mb-1 mid:ml-auto mid:mr-8 min:mt-40 z-40",
        isHome && "xxl:mr-40",
      )}
    >
      <button
        onClick={handleConnection}
        className={twMerge(
          "hover:bg-hover w-full text-center",
          !marketcard &&
            "max-w-max min:mt-12 px-4 py-1 rounded-full hover:text-neutral-50 uppercase",
          className,
        )}
      >
        Connect
      </button>
    </div>
  );
}
